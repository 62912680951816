/* global styles */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

* {
  font-family: 'Poppins', sans-serif;
}

html {
  font-family: 'Nunito', sans-serif;
  font-size: 10px;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

p {
  font-size: 1.6rem;
}

img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

section {
  padding: 1rem 0;
  width: 100%;
}

main {
  background: #fff;
  padding: 2.5rem 0 5rem;
}

main.product-content {
  position: relative;
  top: 38px;
}

.white-link {
  color: #fff;
  transition: all 0.5s ease;
}

.white-link:hover {
  color: #c2b8b8;
  text-decoration: none;
}

.red-border {
  border: 1px solid red;
  height: 10vh;
}

.blue-bg {
  background: lightblue;
}

.excellent-red {
  color: #ec4125;
}

.kovad-black {
  color: #2c2629;
}

@media screen and (max-width: 850px) {
  main.product-content {
    /* position: relative; */
    /* top: 400px !important; */
  }
}
