.container {
  display: grid;
  place-items: center;
  height: 100vh;
}

.innerContainer {
  display: inline-block;
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
