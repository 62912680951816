.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 4rem);
}

.container > h2 {
  font-size: 10rem;
}

.message {
  border: 2px #ec4125 solid;
  border-radius: 5px;
  font-size: 24px;
  color: #ec4125;
  text-align: center;
  padding: 20px;
}

@media screen and (min-width: 1025px) {
  .message {
    padding: 40px;
  }
}

.link {
  cursor: pointer;
  padding-top: 1.5rem;
  text-decoration: underline;
}
